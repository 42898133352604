import { defineStore } from 'pinia'
import { store } from '@/store'
import { UserLoginType } from '@/api/login/types'
import { ElMessageBox } from 'element-plus'
import { useI18n } from '@/hooks/web/useI18n'
import { loginOutApi } from '@/api/login'
import { useTagsViewStore } from './tagsView'
import { useEmitt } from '@/hooks/event/useEmitt'
import { useStorage } from '@/hooks/web/useStorage'
import { INotice } from '@/api/user'
const { clear } = useStorage()
const { emitter } = useEmitt()
interface Bal {
  kk_bal: number
  hk_bal: number
  mo_bal: number
}
interface UserState {
  userInfo?: LoginUserInfo
  tokenKey: string
  token: string
  roleRouters?: string[] | AppCustomRouteRecordRaw[]
  rememberMe: boolean
  loginInfo?: UserLoginType
  reset_password: boolean
  userBaseInfo: UserBaseInfo
  gameWin: IGameWinRes
  //未结算金额
  betting: number
  bal: Bal
  statements: IStatements
  noticeList: INotice[]
  noticeDialogVisualize: boolean | undefined
  noticeDialogListVisualize: boolean | undefined
  loginShowNoticeDialogStatus: boolean
}

export const useUserStore = defineStore('user', {
  state: (): UserState => {
    return {
      userInfo: undefined,
      tokenKey: 'token',
      token: '',
      roleRouters: undefined,
      // 记住我
      rememberMe: false,
      loginInfo: undefined,
      reset_password: true,
      userBaseInfo: {} as UserBaseInfo,
      gameWin: {} as IGameWinRes,
      betting: 0,
      bal: {
        kk_bal: 0,
        hk_bal: 0,
        mo_bal: 0
      },
      statements: {} as IStatements,
      noticeList: [] as INotice[],
      noticeDialogVisualize: false,
      noticeDialogListVisualize: false,
      loginShowNoticeDialogStatus: true
    }
  },
  getters: {
    getLoginShowNoticeDialogStatus(): boolean {
      return this.loginShowNoticeDialogStatus
    },
    getNoticeDialogVisualize(): boolean | undefined {
      return this.noticeDialogVisualize
    },
    getNoticeDialogListVisualize(): boolean | undefined {
      return this.noticeDialogListVisualize
    },
    getNoticeList(): INotice[] {
      return this.noticeList
    },
    getBal(): Bal {
      return this.bal
    },
    getBetting(): number {
      return this.betting
    },
    getGameWin(): IGameWinRes {
      return this.gameWin
    },
    getUserBaseInfo(): UserBaseInfo {
      return this.userBaseInfo
    },

    getTokenKey(): string {
      return this.tokenKey
    },
    getToken(): string {
      return this.token
    },
    getUserInfo(): LoginUserInfo | undefined {
      return this.userInfo
    },
    getRoleRouters(): string[] | AppCustomRouteRecordRaw[] | undefined {
      return this.roleRouters
    },
    getRememberMe(): boolean {
      return this.rememberMe
    },
    getLoginInfo(): UserLoginType | undefined {
      return this.loginInfo
    },
    getResetPassword(): boolean {
      return this.reset_password
    }
  },
  actions: {
    setLoginShowNoticeDialogStatus(loginShowNoticeDialogStatus: boolean) {
      this.loginShowNoticeDialogStatus = loginShowNoticeDialogStatus
    },
    setNoticeDialogVisualize(visualize: boolean | undefined) {
      this.noticeDialogVisualize = visualize
    },
    setNoticeDialogListVisualize(visualize: boolean | undefined) {
      this.noticeDialogListVisualize = visualize
    },
    setNoticeList(noticeList: INotice[]) {
      this.noticeList = noticeList
    },
    setStatements(statements: IStatements, lottery: 1 | 2 | 3) {
      this.setBal(statements.data.bal, lottery)
      const gameWin = this.gameWin
      gameWin.r = statements.data.r
      this.setGameWin(gameWin)
      this.statements = statements
    },
    setBal(bal: number, lottery: 1 | 2 | 3) {
      switch (lottery) {
        case 1:
          this.bal.kk_bal = bal
          break
        case 2:
          this.bal.hk_bal = bal
          break
        case 3:
          this.bal.mo_bal = bal
      }
    },
    setBetting(betting: number) {
      this.betting = betting
    },
    setGameWin(gameWin: IGameWinRes) {
      this.gameWin = gameWin
    },
    setUserBaseInfo(userBaseInfo: UserBaseInfo) {
      this.bal.kk_bal = userBaseInfo.sg_member_info.kk_bal
      this.bal.hk_bal = userBaseInfo.sg_member_info.hk_bal
      this.bal.mo_bal = userBaseInfo.sg_member_info.mo_bal
      this.userBaseInfo = userBaseInfo
    },
    setTokenKey(tokenKey: string) {
      this.tokenKey = tokenKey
    },
    setToken(token: string) {
      this.token = token
    },
    setUserInfo(userInfo?: LoginUserInfo) {
      this.userInfo = userInfo
    },
    setRoleRouters(roleRouters: string[] | AppCustomRouteRecordRaw[]) {
      this.roleRouters = roleRouters
    },
    setResetPassword(reset_password: boolean) {
      this.reset_password = reset_password
    },
    async logoutConfirm() {
      const { t } = useI18n()
      // ElMessageBox.confirm(t('common.loginOutMessage'), t('common.reminder'), {
      //   confirmButtonText: t('common.ok'),
      //   cancelButtonText: t('common.cancel'),
      //   type: 'warning'
      // })
      //   .then(async () => {
      //     await loginOutApi().catch(() => {})
      //     this.reset()
      //   })
      //   .catch(() => {})
      if (confirm(t('common.loginOutMessage'))) {
        await loginOutApi().catch(() => {})
        this.reset()
      }
    },
    reset() {
      const tagsViewStore = useTagsViewStore()
      tagsViewStore.delAllViews()
      this.setToken('')
      this.setUserInfo(undefined)
      this.setRoleRouters([])
      clear()

      emitter.emit('websocketClose')
      // router.replace('/login')
      window.location.reload()
    },
    logout() {
      this.reset()
    },
    setRememberMe(rememberMe: boolean) {
      this.rememberMe = rememberMe
    },
    setLoginInfo(loginInfo: UserLoginType | undefined) {
      this.loginInfo = loginInfo
    }
  },
  // persist: true
  persist: [
    {
      storage: sessionStorage,
      paths: [
        'userInfo',
        'token',
        'rememberMe',
        'loginInfo',
        'roleRouters',
        'loginShowNoticeDialogStatus'
      ]
    }
  ]
})

export const useUserStoreWithOut = () => {
  return useUserStore(store)
}
